import React, { useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 

/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
let images = [];

const Post = ({ data, location }) => {
    const post = data.ghostPost

    const [ isOpen, setIsOpen ] = useState(false);  
    const [ photoIndex, setPhotoIndex ] = useState(0);

    useLayoutEffect(()=>{
        let galleryImages = document.querySelectorAll('.kg-gallery-image > img');
        let lastWasTall = false;
        let spaceLeft = 0;
        galleryImages.forEach((img, index) =>{
            images.push(img.src);
            img.addEventListener('click', ()=>{
                setPhotoIndex(index);
                setIsOpen(true);
            })
            let isNewRow = false;
            if(spaceLeft <= 0){
                isNewRow = true;
                spaceLeft = 4;
            }
            if(parseInt(img.attributes.width.nodeValue) >= parseInt(img.attributes.height.nodeValue)){
                if( spaceLeft===3 || spaceLeft===4){}
                if(!isNewRow && lastWasTall){
                    galleryImages[index-1].classList.remove('tall');
                    galleryImages[index-1].classList.add('tallShared');
                }
                if(!lastWasTall && spaceLeft===4){
                    img.classList.add('wide');
                    spaceLeft -= 4;
                }else{
                    img.classList.add('wideShared');
                    spaceLeft -= 3;
                }
                lastWasTall = false;
            }else{
                if(!isNewRow && lastWasTall && spaceLeft===3){
                    galleryImages[index-1].classList.remove('tallShared');
                    galleryImages[index-1].classList.add('tall');
                }
                if(isNewRow || (spaceLeft===2)){
                    img.classList.add('tall');
                    spaceLeft -= 2;
                }else{
                    img.classList.add('tallShared');
                    spaceLeft -= 1;
                }
                lastWasTall = true;
            }
        });
        return () => {
            // Clean up the subscription
            images = [];
            galleryImages.forEach((img, index) =>{
                images.push(img.src);
                img.addEventListener('click', ()=>{
                    setPhotoIndex(index);
                    setIsOpen(true);
                })
            });
        };
    });

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="article"
            />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>
            <Layout>
                <div className="container">
                    <article className="content">
                        { post.feature_image ?
                            <figure className="post-feature-image">
                                <img src={ post.feature_image } alt={ post.title } />
                            </figure> : null }
                        <div className="pjs-post-text tw-w-full tw-max-w-screen-lg tw-mx-auto">
                            <div className="tw-max-w-7xl">
                                <h1 className="content-title tw-mb-0 tw-text-left">{post.title}</h1>
                            </div>
                        <section className="post-full-content">

                            {/* The main post content */ }
                            <section
                                className="content-body load-external-scripts"
                                dangerouslySetInnerHTML={{ __html: post.html }}
                            />
                        </section>
                        </div>
                    </article>
                </div>
                { isOpen && (
                    <Lightbox 
                        mainSrc={images[photoIndex] ? images[photoIndex] : '' }
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        onCloseRequest={() => setIsOpen(false)}
                        onMovePrevRequest={() =>
                            setPhotoIndex((photoIndex + images.length - 1) % images.length)
                        }
                        onMoveNextRequest={() =>
                            setPhotoIndex( (photoIndex + 1) % images.length)
                        }
                    />
                )}

            </Layout>
        </>
    )
}

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Post;

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
    }
`
